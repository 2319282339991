.custom_table.template_table .MuiDataGrid-row .MuiDataGrid-cell{
    text-wrap: nowrap !important;
    min-height: 69px !important;
}
.custom_table.template_table .MuiDataGrid-row .MuiDataGrid-cell{
    padding: 10px 20px;
}
.custom_table.template_table .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeader{
    padding: 0px 20px !important;
}
.custom_table.template_table .MuiLink-root.view_btn_style{
    font-style: normal;
    font-size: 13px;
    line-height: 20px;
}
.template_table .MuiDataGrid-row .MuiDataGrid-cell[data-field="createdAt"] {
    position: relative;
}
.template_table .MuiDataGrid-row .MuiDataGrid-cell[data-field="createdAt"]:before{
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 123, 255, 0.05);
}
.custom_table.template_table .MuiDataGrid-row .MuiDataGrid-cell[data-field="times_used"] {
    padding-left:7% !important;
}
.custom_table.template_table .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeader[aria-label="Times Used"]{
    padding-left:7% !important;
}
.del_btn{
    font-size: 14px;
    line-height: 20px;
}
.details_table_container{
    padding:0px !important;
}