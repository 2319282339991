*{
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif !important;
    text-decoration: none;
}
.fa {
    font-family: "Font Awesome 6 Free" !important;
}
.MuiTypography-h1{
    font-size:25px !important;
    line-height: 28px !important;
}
.MuiTypography-h2{
    font-size:22px !important;
    line-height: 36px !important;
}
.MuiTypography-h3{
    font-size:20px !important;
    line-height: 23px !important;
}
.MuiTypography-h6{
    font-size:12px !important;
    line-height: 17px !important;
}
.MuiTypography-p{
    font-size:13px !important;
    line-height: 18px !important;
}
.MuiTypography-body1{
    font-size:14px !important;
    line-height: 20px !important;
}
.MuiTypography-body2{
    font-size:14px !important;
    line-height: 20px !important;
}
.color_red{
    color:#FF4C86 !important;
}
.color_blue{
    color: #007BFF !important;
}
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    padding-left:5px;
}
/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}
/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    width: 3px;
    border-radius: 10px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
.custom_table .MuiLink-root.view_btn_style{
    font-size: 12px;
    line-height: 17px;
    font-style: italic;
    color: #007BFF;
    text-transform: capitalize;
}
.custom_table .MuiDataGrid-columnHeaders{
    background: #007BFF;
    border: none;
    border-radius: 0px;
}
.custom_table .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeaderTitle{
    color: #fff !important;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    text-wrap: wrap;
}
.css-ekndaq-MuiDataGrid-root .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell{
    color: #2B3033;
    font-size: 13px;
    line-height: 18px;
    font-weight: 400;
}
.custom_table .MuiCheckbox-root{
    width: 24px;
    height: 24px;
    border-radius: 5px;
    border: 2px solid #C1C4CD;
    color: #fff;
}
.custom_table .MuiCheckbox-root input{
    opacity: 0 !important;
}
.custom_table .MuiCheckbox-root.Mui-checked{
    border: 2px solid #007BFF;
    color: #007BFF;
}
.custom_table .MuiCheckbox-root.Mui-checked svg{
    width: 0.8em;
    height: 0.8em;
}
.custom_table .MuiDataGrid-columnHeaders .MuiCheckbox-root.Mui-checked {
    border: 2px solid #fff;
    color: #fff;
}
.custom_table .MuiDataGrid-columnHeaders .MuiCheckbox-root.Mui-checked svg{
    color: #fff;
}
.custom_table  .MuiDataGrid-row.odd{
    background: #F4F5F8 !important;
}
.custom_table .MuiDataGrid-footerContainer,.custom_table{
    border: none !important;
}
.custom_table .MuiDataGrid-row .MuiDataGrid-cell{
    border: none;
    font-size: 13px;
    line-height: 20px;
    font-weight: 400;
    color: #2B3033;
    text-wrap: wrap !important;
    max-height: inherit !important;
    min-height:inherit !important;
    height: auto !important;
    padding: 10px;
}
.custom_table .MuiDataGrid-row .MuiDataGrid-cell[data-field="Campaigns"] {
    position: relative;
}
.custom_table .MuiDataGrid-row .MuiDataGrid-cell[data-field="Campaigns"]:before{
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 123, 255, 0.05);
}
.custom_table .MuiDataGrid-main{
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1) !important;
}
.custom_table .MuiDataGrid-row{
    height: auto !important;
    max-height: inherit !important;
    min-height: inherit !important;
}

.custom_table .MuiDataGrid-virtualScrollerContent{
    height: 100% !important;
}
.custom_table .MuiDataGrid-virtualScrollerRenderZone{
    position: relative !important;
}
.custom_table .MuiDataGrid-footerContainer{
    padding: 20px 10px;
}
.custom_table .MuiDataGrid-footerContainer .MuiTablePagination-selectLabel{
    font-size: 13px;
    line-height: 20px;
    color: #2B3033;
    order: 1;
}
.custom_table .MuiDataGrid-footerContainer .MuiInputBase-root{
    order: 0;
    margin-right: 10px !important;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.07);
    border-radius: 5px;
    padding: 0 6px;
}
.custom_table .MuiDataGrid-footerContainer .MuiTablePagination-displayedRows{
    order: 2;
    font-size: 13px;
    line-height: 20px;
    color: #2B3033;
    padding: 0 5px;
}
.custom_table .MuiDataGrid-footerContainer .MuiPagination-root{
    order: 3;
}
.custom_table .MuiDataGrid-footerContainer .MuiPagination-root button{
    font-size: 13px;
    line-height: 20px;
    color: #2B3033;
    min-width: 39px;
    height: 39px;
    border-radius: 50px;
    background-color: #F4F5F8;
}
.custom_table .MuiDataGrid-footerContainer .MuiPaginationItem-previousNext{
    padding: 0 14px;
}
.custom_table .MuiDataGrid-footerContainer .MuiPaginationItem-root.Mui-selected {
    color: #fff;
    background-color: #007BFF;
}
.custom_table .MuiDataGrid-row .job_number{
    color: #007BFF;
}
.custom_table .MuiDataGrid-row .last_name,
.custom_table .MuiDataGrid-row .move_type{
    max-width: min-content;
}
.green_text{
    color: #23BD47 !important;
}
.orange_text{
    color: #EA980F !important;
}
.red_text{
    color: #FF0053 !important;
}
.days_ago_sub{
    font-size: 12px;
    line-height: 17px;
    font-style: italic;
    color: #A8ADB0;
}
.sub_menu_lead .MuiButton-root{
    font-size: 13px;
    line-height: 20px;
    color: #2B3033;
    border-radius: 50px;
    border: 1px solid #2B3033;
    text-transform: inherit;
}
.sub_menu_lead .MuiMenu-paper{
    border-radius: 5px !important;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.12);
}
.custom_table .filter_items_btn{
    background: #F4F5F8;
    font-size: 14px;
    line-height: 18px;
    color: #2B3033;
    text-transform: capitalize;
    padding: 8px 10px;
    margin-right: 10px;
}
.custom_table .filter_items_btn.active{
    color: #fff;
    background: #007BFF;
}
.tabs_btn_content .tab_btn {
        color: #2B3033;
        font-weight:500;
        font-size:15px;
        line-height:23px;
}
.tabs_btn_content .tab_btn.MuiTab-root.Mui-selected {
    color: #007BFF !important;
    border-bottom:4px solid #007BFF;
}
.date_picker_field .MuiButtonBase-root .MuiSvgIcon-root{
    color: #007BFF;
}