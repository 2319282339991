.dont_show_btn .MuiFormControlLabel-label{
    font-size: 15px;
    line-height: 23px;
    font-weight: 500;
    color: #2B3033;
    text-transform: uppercase;
}
.new_campaign_popup .MuiCheckbox-root{
    width: 24px;
    height: 24px;
    border-radius: 5px;
    border: 2px solid #C1C4CD;
    color: #fff;
}
.new_campaign_popup .MuiCheckbox-root input{
    opacity: 0 !important;
}
.new_campaign_popup .MuiCheckbox-root.Mui-checked{
    border: 2px solid #007BFF;
    color: #007BFF;
}
.new_campaign_popup .MuiCheckbox-root.Mui-checked svg{
    width: 0.8em;
    height: 0.8em;
}
.new_campaign_popup .MuiDataGrid-columnHeaders .MuiCheckbox-root.Mui-checked {
    border: 2px solid #fff;
    color: #fff;
}
.new_campaign_popup .MuiDataGrid-columnHeaders .MuiCheckbox-root.Mui-checked svg{
    color: #fff;
}
.active_create_campaign{
    z-index: 999999;
}
.active_create_campaign .count_campaign_step{
    display: flex;
}
.new_campaign_step_style{
    border-radius: 8px;
    position: relative;
    background: #EBECF0;
}
.count_campaign_step{
    position: absolute;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border:2px solid #007BFF;
    color:#007BFF;
    font-size: 14px;
    line-height: 21px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    display: none;
}
.count_campaign_step_left{
    top: -25px;
    right: -20px;
}
.count_campaign_step_right{
    top: -25px;
    left: -20px;
}