.info_job{
    background: none;
}
.job_info_title{
    color:#2B3033;
    font-size: 22px;
    font-weight: 600;
    line-height: 30px;
}
.job_info_title span{
    color: #007BFF;
}
.job_info_sub{
    color:#2B3033;
    font-size: 15px;
    font-weight: 400;
    line-height: 30px;
}
.custom_table.per_lead_table .MuiDataGrid-row .MuiDataGrid-cell{
    min-height: 59px !important;
}
.custom_table.per_lead_table .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader--moving{
    background: none;
}
.messageInfo{
    background: #DBECFF;
    transition: 1s;
    width:350px;
    height: 100%;
    box-shadow:inset 6px 0px 20px rgba(0,0,0,0.15);
    animation: 0.5s linear popupopen;
    opacity: 1;
}

@keyframes popupopen {
    from {opacity: 0}
    to {opacity: 1}
}
