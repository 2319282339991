.template_list_style{
    background: #FFFFFF;
    border-radius: 8px;
    margin-bottom: 8px;
    padding: 2px 16px !important;
}
.poper_template .MuiPopover-paper{
    bottom: 0px !important;
    max-height: inherit !important;
    top: 0 !important;
    margin-left: -20px !Important;
    width: 350px;
    border-radius: 0px !important;
    box-shadow: inset -10px 0px 30px rgba(0, 0, 0, 0.25) !important;
    right: 780px;
    left: auto !important;
}
.list_template_name{
    font-size: 15px;
    line-height: 30px;
    color: #2B3033;
}
.temp_create_date{
    font-style: italic;
    color: #A8ADB0;
}
.active_temp .list_template_name{
    color: #007BFF;
}
.sms_table_style{
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
}
.sms_table_style tbody td{
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: #2B3033;
    border: none;
    padding: 10px 20px;
    height: 35px;
}
.sms_table_style button{
    color:#007BFF;
    text-transform: capitalize;
}
.my-custom-view-wrapper {
    display: flex;
    flex-direction: column;

}
.my-custom-view-wrapper div:first-child {
    /* Here we are styling the button container. */
    font-size: 15px;
    line-height: 25px;
    color: #2B3033;
    background: #F4F5F8;
    border-radius:15px 20px 15px 20px;
    padding: 20px;
}
.my-custom-view-wrapper .textarea_campaign_temp{
    font-size: 15px;
    line-height: 25px;
    color: #2B3033;
    border-radius:15px 20px 15px 20px;
    padding: 10px;
    min-height: 300px;
}
.my-custom-view-wrapper div:last-child {
    /* Here we are styling the button container. */
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    margin-top: 5px;
}
.MuiStep-horizontal .MuiStepIcon-root{
    color: #ffffff;
    border: 2px solid #E0E3EB;
    border-radius: 50%;
    width: 36px;
    height: 36px;
}
.MuiStep-horizontal .MuiStepIcon-root.Mui-active {
    color: #ffffff;
    border: 2px solid #007BFF;
    border-radius: 50%;
}
.MuiStep-horizontal .MuiStepIcon-text {
    fill: #A5A7B7;
    font-size: 11px;
    line-height: 21px;
    font-weight: 600;
}
.MuiStep-horizontal .Mui-active .MuiStepIcon-text {
    fill: #007BFF;
}
.MuiStep-horizontal .MuiStepIcon-root.Mui-completed{
    color:#007BFF;
    width: 40px;
    height: 40px;
}
.MuiPickersPopper-root .MuiMultiSectionDigitalClock-root[aria-label="Select meridiem"]{
    display: none !important;
}
.chat_gpt_content .logo_gpt{
    background:#74AA9C;
    border:1px solid #74AA9C;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 10px;
    width: 40px;
    height: 40px;
    position: relative;
    top: 1px;
    left: -10px;
}
.chat_gpt_content .logoTitle{
    font-size: 15px;
    line-height: 30px;
}
.chat_gpt_content .btn_def_gpt{
    border: 1px solid #E0E3EB;
    background:#F4F5F8;
    font-size: 13px;
    line-height: 18px;
    font-weight: 500;
    color: #2B3033;
    padding: 5px 12px !important;
    border-radius: 100px;
    text-transform: capitalize;
}
.chat_gpt_content .btn_def_gpt.active_btn_gpt{
    color: #fff;
    border: 1px solid #74AA9C;
    background:#74AA9C;
}
.chat_gpt_content .undo_btn{
    font-size: 14px;
    line-height: 14px;
    font-weight: 600;
    color: #2B3033;
    background: #F4F5F8;
    border-radius: 100px;
    padding: 10px 14px !important;
    min-width: auto;
}
.select_gpt .MuiInputLabel-root{
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: #2B3033;
    top:-5px;
}
.select_gpt .MuiSelect-select{
    background: #F4F5F8;
    border-radius: 100px !important;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.14);
    padding: 10px 20px;
}
.select_gpt .MuiSelect-select[aria-expanded="true"]{
    border-radius: 20px 20px 0 0 !important;
}
.select_gpt .MuiOutlinedInput-notchedOutline{
    border:none !important;
}
.select_gpt .MuiOutlinedInput-root{
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: #2B3033;
}
.MuiList-root .MuiMenuItem-root.commands_gpt_item{
    font-size: 14px;
    line-height: 22px;
    font-weight: 400 !important;
    color: #2B3033;
    padding: 0 20px;
}
.MuiList-root  .MuiMenuItem-root.commands_gpt_item.Mui-selected,
.MuiList-root  .MuiMenuItem-root.commands_gpt_item.Mui-selected:hover{
    color: #74AA9C;
    font-weight: 600 !important;
    background: none;
}
#menu- .MuiPaper-root {
    box-shadow: 0px 9px 10px rgba(0, 0, 0, 0.14) ;
    border-radius: 0 0 20px 20px;
    background: #F4F5F8;
    border-top: 1px solid #E0E3EB;
}
.input_gpt .MuiInputBase-input{
    background: #F4F5F8;
    box-shadow: 0px 9px 10px rgba(0, 0, 0, 0.14) ;
    border-radius: 100px;
    font-size: 13px;
    line-height: 18px;
    padding: 11px 20px;
}
.input_gpt .MuiInputBase-root{
    background: none !important;
}
.input_gpt .MuiInputBase-root:before,
.input_gpt .MuiInputBase-root:after{
    content:none !important;
}
.chat_gpt_content .btn_input_gpt{
    font-size: 14px;
    line-height: 14px;
    font-weight: 600;
    border-radius: 100px;
    padding: 10px 14px !important;
    min-width: auto;
    color: #fff;
    border:none;
    background: #74AA9C;
}
.chat_gpt_content .btn_input_gpt:hover{
    background: #74AA9C;
}
