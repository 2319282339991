.custom_table.campaigns_table .MuiLink-root.view_btn_style{
    font-style: normal;
    font-size: 13px;
    line-height: 20px;
}
.campaigns_table .MuiDataGrid-row .MuiDataGrid-cell[data-field="createdAt"] {
    position: relative;
}
.campaigns_table .MuiDataGrid-row .MuiDataGrid-cell[data-field="createdAt"]:before{
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 123, 255, 0.05);
}
.custom_table.campaigns_table .MuiDataGrid-row .MuiDataGrid-cell{
    text-wrap: nowrap !important;
    min-height: 55px !important;
}
.label_checkbox_filter .MuiFormControlLabel-label{
    font-size: 15px;
    line-height: 23px;
    font-weight: 500;
    color: #2B3033;
    text-transform: uppercase;
}
.label_checkbox_filter .MuiCheckbox-root{
    width: 24px;
    height: 24px;
    border-radius: 5px;
    border: 2px solid #C1C4CD;
    color: #fff;
    background: #fff;
    margin-right: 15px;
}
.label_checkbox_filter .MuiCheckbox-root input{
    opacity: 0 !important;
}

.label_checkbox_filter .MuiCheckbox-root input::chacked{
    border: 2px solid #007BFF !important;
    color: #007BFF !important;
    background: #fff;
}
.label_checkbox_filter .MuiCheckbox-root.Mui-checked svg{
    width: 0.8em;
    height: 0.8em;
}
.MuiButton-root.cancel_campaign_btn{
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    color: #fff;
    background: #FF4C86;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    height: 34px;
    text-transform: inherit;
}
.MuiButton-root.cancel_campaign_btn:hover{
    background: #FF0053 !important;
}
.MuiButton-root.start_campaign_btn{
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    color: #fff;
    background: #007BFF;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    height: 34px;
    text-transform: inherit;
}