.Filter_Tab .filter_tab_btn{
    color: #2B3033;
    box-shadow:0 7px 15px rgba(0,0,0,0.03);
    border-radius:10px;
    min-height:40px;
    text-align: left;
    align-items: center;
    font-weight:400;
    font-size:15px;
    line-height:30px;
    background:#fff;
    text-transform:capitalize;
    justify-content: space-between;
}
.Filter_Tab .MuiTab-root.Mui-selected {
    color: #2B3033 !important;
    background: #F0F7FF;
    border: 1px solid #007BFF;
}

.date_picker_field{
    width: 100%;
    min-width: unset !important;
}
.date_picker_field label.MuiInputLabel-shrink,.filters_field label.MuiInputLabel-shrink{
    font-size: 11px;
    line-height: 15px;
    color: #007BFF;
    padding: 5px;
    top: 0;
    left:12px;
    text-transform: uppercase;
    transform: translate(4px, -12px) scale(1);
}
.date_picker_field fieldset,.filters_field fieldset{
    padding: 0 15px;
    border-color: #D5D7DC;
}
.date_picker_field label,
.filters_field label{
    font-size: 15px;
    line-height: 25px;
    color: #2B3033;
    padding: 0px 5px;
    top:-5px;
}
.date_picker_field .MuiInputBase-input,.filters_field .MuiInputBase-input{
    font-size: 15px;
    line-height: 25px;
    color: #2B3033;
    padding: 14px 20px;
    min-width: 20px;
}
.edit_modal .date_picker_field .MuiInputBase-input{
    font-size: 15px;
    line-height: 25px;
    color: #2B3033;
    padding: 14px 0px 14px 20px;
    min-width: 20px;
}
.date_picker_field .MuiOutlinedInput-root,.filters_field .MuiOutlinedInput-root{
    border-radius: 50px;
}
.date_picker_field .MuiOutlinedInput-root.Mui-focused fieldset,.filters_field .MuiOutlinedInput-root.Mui-focused fieldset{
    border-color: #007BFF;
    border-width: 1px;
    background: rgba(0,123,255,0.02);
}
.filters_field .MuiInputAdornment-root p{
    font-size: 13px;
    line-height: 18px;
    font-weight: 500;
    color: #2B3033;
}
.date_picker_amPm{
    min-width: max-content !important;
    margin-left: 0px !important;
}
.date_picker_amPm .MuiPickersLayout-contentWrapper,.date_picker_amPm .MuiDialogActions-root,.date_picker_amPm .MuiPickersToolbar-root .MuiTypography-overline,.date_picker_amPm .MuiTimePickerToolbar-hourMinuteLabel{
    display: none !important;
}
.MuiTimePickerToolbar-ampmSelection{
    flex-direction: row !important;
}
.date_picker_amPm .MuiPickersLayout-toolbar{
    padding:10px 0!important;
}
.date_picker_amPm .MuiTimePickerToolbar-ampmLabel{
    font-size: 15px !important;
    line-height: 30px !important;
    background: #FFFFFF;
    border: 1px solid #CAD2E8;
    padding: 8px 0 !important;
    width: 34px;
}
.date_picker_amPm .MuiButton-root:first-child .MuiTimePickerToolbar-ampmLabel{
    border-radius: 5px 0px 0px 5px;
}
.date_picker_amPm .MuiButton-root:last-child .MuiTimePickerToolbar-ampmLabel{
    border-radius: 0px  5px  5px 0px;
    border-left: none;
}
.date_picker_amPm .MuiTimePickerToolbar-ampmLabel.Mui-selected{
    background: #007BFF ;
    color: #fff;
}
.modal-description .chip_filter_btn,.edit_modal .chip_filter_btn{
    font-size: 13px;
    line-height: 18px;
    font-weight: 500;
    color: #2B3033;
    background: #F4F5F8;
    border:1px solid #E0E3EB;
    border-radius: 50px;
    text-transform: inherit;
    padding: 4px 10px;
    min-width: unset;
}

.modal-description .chip_active,.edit_modal .chip_active{
    background: #007BFF;
    border:1px solid #007BFF;
    color: #fff;
}
.MuiModal-root .cancle_modal_btn{
    text-transform:capitalize;
    font-size:14px;
    color:#2B3033;
    background:#fff;
    font-weight:500;
    box-shadow:0 3px 6px rgba(0,0,0,0.09)
}
.MuiModal-root .cancle_childModal_btn{
    text-transform:capitalize;
    font-size:14px;
    color:#2B3033;
    background:#F4F5F8;
    font-weight:500;
    box-shadow:0 3px 6px rgba(0,0,0,0.09)
}
.MuiModal-root .view_results_btn{
    text-transform:capitalize;
    font-size:14px;
    background:#007BFF;
    font-weight:500;
    box-shadow:0 3px 6px rgba(0,0,0,0.09);
    opacity:0.3
}
.MuiModal-root .view_results_btn.active{
    opacity: 1;
}
.MuiModal-root .remove_all_filters{
    text-transform:capitalize;
    font-size:13px;
    line-height: 20px;
    font-weight:500;
    border-radius: 50px;
    border-color: #FF4C86;
    color: #FF4C86;
}
.modal-description{
    display: flex;
    height: calc(100% - 132px);
    overflow: auto;
}
.filter_count .MuiBadge-badge{
    font-size: 11px;
    line-height: 15px;
    font-weight: 600;
    height: 18px;
    min-width: 18px;
    background: #007BFF;
    width: 18px;
    top: 3px;
}
.count_shadow .MuiBadge-badge{
    font-size: 11px;
    line-height: 15px;
    font-weight: 600;
    height: 19px;
    min-width: 19px;
    background: #007BFF;
    width: 19px;
    top: 2px;
    right: -9px;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 5px 10px rgba(0,0,0,0.3);
}
.modal-description .rounded_icon{
    background: #FFFFFF !important;
    width: 76px;
    height: 76px;
    box-shadow: 0px 11px 17px rgba(0,0,0,0.06) !important;
    font-size: 40px;
    line-height: 40px;
    color: #2B3033;
    margin-bottom: 10px;
}
.modal-title{
    background:#F4F5F8;
}
.modal-footer{
    background:#F4F5F8;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    min-height: 54px;
    align-items: center;
}
.rounded_cout{
    color: #2B3033;
    font-size: 38px;
    line-height: 46px;
    font-weight: 700;
}
.btn_gray_style{
    font-size: 14px !important;
    color: #2B3033 !important;
    line-height: 14px !important;
    background:#F4F5F8 !important;
    padding: 8px 10px !important;
    text-transform: uppercase;
}
.textarea_style{
    border: 1px solid #D5D7DC;
    border-radius: 20px;
    padding: 15px;
    width: -webkit-fill-available;
    font-size: 15px;
    line-height: 25px;
    color: #2B3033;
    height:calc(100% - 280px) !important;
}
.chip_shortcode{
    font-size: 15px !important;
    line-height: 30px;
    color: #2B3033 !important;
    background: #fff !important;
    width: 100%;
    text-align: left;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.03);
    padding: 20px 5px !important;
    justify-content: flex-start !important;
    border-radius: 10px !important;
    margin-bottom: 10px !important;
}

.search_field .MuiOutlinedInput-root{
    background: #fff !important;
    border-radius: 50px !important;
}
.search_field  .MuiInputBase-input{
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    font-style: italic;
    padding: 14px 20px;
}
.date_time_change .MuiTypography-body2{
    font-weight: 500;
    color: #2B3033;
    margin-top:30px;
}
.edit_modal .date_time_change .MuiTypography-body2{
    margin-top:20px;
}
.MuiDataGrid-panel{
    transform: translate(-262px, 121px) !important;
    top: 0 !important;
    right: 0 !important;
    left: auto !important;
}
button[editext="edit-button"] {
    color: #007BFF;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    background: none;
    outline: none;
    border: none;
}
button[editext="cancel-button"], button[editext="save-button"]{
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    outline: none;
    border: none;
    background: none;
}
.view_templ_cont{
    max-height: 300px;
    overflow: auto;
}