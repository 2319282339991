#primary-search-account-menu ul li{
    font-size: 14px;
    color: #2B3033;
    font-weight: 500;
    line-height: 20px;
}
#primary-search-account-menu .MuiMenu-paper{
    max-height:none !important;
}
#root .css-hyum1k-MuiToolbar-root {
    min-height: 50px;
}
#root .MuiAvatar-root{
    height:50px;
    width: 50px;
    font-size: 20px;
}
.tab_link a{
    color:#2B3033;
    font-size: 15px;
    line-height: 23px;
    padding: 16px 20px 15px;
    width: 130px;
    display: inline-block;
    text-align: center;
    border-bottom: 4px solid transparent;
}
.tab_link a.active{
    border-bottom: 4px solid #007BFF;
    color: #007BFF;
}
.Campaign_btn{
    font-size: 14px !important;
    color: #2B3033 !important;
    font-weight: 600 !important;
    line-height: 14px !important;
    background:#fff !important;
    padding: 8px 10px !important;
    text-transform: uppercase;
    box-shadow: 0px 5px 10px rgba(0,0,0,0.1);
}
.custom_table .MuiDataGrid-columnHeaders{
    background: #007BFF;
    border: none;
}
.custom_table .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeaderTitle{
    color: #fff !important;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
}
.css-ekndaq-MuiDataGrid-root .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell{
    color: #2B3033;
    font-size: 13px;
    line-height: 18px;
    font-weight: 400;
}
.custom_table .MuiCheckbox-root{
    width: 24px;
    height: 24px;
    border-radius: 5px;
    border: 2px solid #C1C4CD;
    color: #fff;
}
.custom_table .MuiCheckbox-root input{
    opacity: 0 !important;
}
.custom_table .MuiCheckbox-root.Mui-checked{
    border: 2px solid #007BFF;
    color: #007BFF;
}
.custom_table .MuiCheckbox-root.Mui-checked svg{
    width: 0.8em;
    height: 0.8em;
}
.custom_table .MuiDataGrid-columnHeaders .MuiCheckbox-root.Mui-checked {
    border: 2px solid #fff;
    color: #fff;
}
.custom_table .MuiDataGrid-columnHeaders .MuiCheckbox-root.Mui-checked svg{
    color: #fff;
}
.custom_table  .MuiDataGrid-row.odd{
    background: #F4F5F8 !important;
}
.custom_table .MuiDataGrid-footerContainer,.custom_table{
    border: none !important;
}
.custom_table .MuiDataGrid-row .MuiDataGrid-cell{
    border: none;
}
.custom_table .MuiDataGrid-main{
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1) !important;
}
.custom_table .MuiDataGrid-row .MuiDataGrid-cell .MuiDataGrid-cellContent{
    font-size: 13px;
    line-height: 20px;
    font-weight: 400;
    color: #2B3033;
}
.custom_table .MuiDataGrid-footerContainer{
    padding: 20px 10px;
}
.custom_table .MuiDataGrid-footerContainer .MuiTablePagination-selectLabel{
    font-size: 13px;
    line-height: 20px;
    color: #2B3033;
    order: 1;
}
.custom_table .MuiDataGrid-footerContainer .MuiInputBase-root{
    order: 0;
    margin-right: 10px !important;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.07);
    border-radius: 5px;
    padding: 0 6px;
}
.custom_table .MuiDataGrid-footerContainer .MuiTablePagination-displayedRows{
    order: 2;
    font-size: 13px;
    line-height: 20px;
    color: #2B3033;
    padding: 0 5px;
}
.custom_table .MuiDataGrid-footerContainer .MuiPagination-root{
    order: 3;
}
.custom_table .MuiDataGrid-footerContainer .MuiPagination-root button{
    font-size: 13px;
    line-height: 20px;
    color: #2B3033;
    min-width: 39px;
    height: 39px;
    border-radius: 50px;
    background-color: #F4F5F8;
}
.custom_table .MuiDataGrid-footerContainer .MuiPaginationItem-previousNext{
    padding: 0 14px;
}
.custom_table .MuiDataGrid-footerContainer .MuiPaginationItem-root.Mui-selected {
    color: #fff;
    background-color: #007BFF;

}