.MuiModal-root .label_checkbox_filter .MuiFormControlLabel-label{
    font-size: 15px;
    line-height: 23px;
    font-weight: 500;
    color: #2B3033;
    text-transform: uppercase;
}
.MuiModal-root .label_checkbox_filter .MuiCheckbox-root{
    width: 24px;
    height: 24px;
    border-radius: 5px;
    border: 2px solid #C1C4CD;
    color: #fff;
}
.MuiModal-root .label_checkbox_filter .MuiCheckbox-root input{
    opacity: 0 !important;
}

.MuiModal-root .label_checkbox_filter .MuiCheckbox-root.Mui-checked{
    border: 2px solid #007BFF;
    color: #007BFF;
}
.MuiModal-root .label_checkbox_filter .MuiCheckbox-root.Mui-checked svg{
    width: 0.8em;
    height: 0.8em;
}